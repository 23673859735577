import * as React from "react";

import Puzzle from "../../Puzzle";
import map from "../../images/m6.png";
import audio from "../../../static/a6.mp3";

const Page = () => {
  return (
    <Puzzle
      title="Riddle 6"
      author="Author: Barbara Celebucka"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      // audio={audio}
      language="en"
      languageUrl="/zagadki/znKe8aLIi5"
    >
      <div>
        <p>
          The "Insure your flat in PZU" neon on the roof of the Kościuszko
          Housing District building at the Kościuszko Square, designed by Jerzy
          Werszler, was lit up in 1975.
        </p>
        <p>
          The relationship between glass and light is inseparable. What if we
          want to enclose the light inside a translucent form? Glass, mercury
          and a vacuum were enough to get the glow effect as early as in the
          17th century. In the mid-19th century, people experimented with
          introducing light into glass tubes by means of gas, and on a larger
          scale, neon signs started to be produced at the beginning of the 20th
          century. In Poland, they were popular in interwar Warsaw, but it is
          their mass production in the 1960s and 1970s that changed the
          landscape of large cities, including Wrocław.
        </p>
        <p>
          The character of the main streets was created by street advertising at
          that time. Glass tubes filled with gas illuminated the streets, giving
          them two contrasting features: urban glamour and cosiness. They warmed
          our city, allowed for mental journeys into modernity, turning Wrocław
          into a big-city home. Warmth, light, sentiment, and nostalgia – we can
          continue the game of associations or think: what do we miss so much?
          Neon signs refer us to a specific moment in history associated with
          the withdrawal of cheap advertising and aesthetic solutions. They also
          refer us to our own memories or ideas about the former aura of the
          city. A neon is a physical object, we can touch it and measure it, but
          when it is towering over us in public space, it is rarely within our
          reach, we admire it from below, we feel its glow. It fills urban
          interiors and changes our perception of a place. It models the urban
          space just like a bedside lamp with warm light which creates the
          intimacy of the room. Illumination of the city is part of the history
          of advertising, the history of public space and aesthetics related to
          a certain chapter in Wrocław's life – the activities of the Reklama
          advertising agency and the designers such as Janusz Tarantowicz,
          Tadeusz Ciałowicz and Jerzy Werszler.
        </p>
        <p>
          The Kościuszko Square has a strongly defined interior, illuminated in
          the evenings by the recently renovated neon that reads "Insure your
          flat in PZU". This is the last moving, narrative neon sign in Wrocław
          which works and operates in its original position. 23 letters, 4
          figures, 700 meters of glass pipes in total were renovated in 2020. I
          cannot help feeling that Wrocław would be a bit different without its
          sneaking thief. Since the glory of neon signs, applied art has never
          surrounded us so tenderly. Could it be that glass tubes modelled into
          different shapes have influenced some part of us?
        </p>
      </div>
    </Puzzle>
  );
};

export default Page;
